import React, { memo, useCallback, useEffect, useState, useRef } from 'react';
import styled from 'styled-components/macro';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Carousel, Form, Image, Radio } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import Cookies from 'js-cookie';

import useToken from 'app/hooks/useToken';
import { PATH } from 'app/constants/path';
import LoginForm from 'app/pages/AuthPage/components/LoginForm';
import RegisterForm from 'app/pages/AuthPage/components/RegisterForm';
import { useAuthSlice } from 'app/pages/AuthPage/slice';
import { BannerIpad, LoginBanner, loginBg, Logo } from 'styles/image';
import { selectAuth } from '../slice/selectors';
import { useMediaQuery } from 'react-responsive';
import { MetaHelmet } from 'app/components/MetaHelmet';
import { loginOAuthPostRequest, loginOAuthRequest } from 'app/apis/auth';
import { actionsToast } from 'app/pages/ToastPage/slice';
import { MetaButton } from 'app/components/Forms';
import { useGoogleLogout } from 'react-google-login';
import { STORAGE_KEY } from 'shared/constants/storageKey';
import { PartnerSite } from '../components/PartnerSite';

export const SignInUpPage = memo(() => {
  const isMobile = useMediaQuery({ maxWidth: '703px' });
  const isTablet = useMediaQuery({ minWidth: '1180px', maxWidth: '1366px' });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const { token } = useToken();
  const [tokenResponse, setTokenResponse] = useState<any>(null);

  const { actions } = useAuthSlice();
  const { isLoading } = useSelector(selectAuth);

  const { pathname } = location;

  const [key, setKey] = useState('login');

  const ref = useRef<HTMLDivElement | null>(null);

  const { signOut } = useGoogleLogout({
    clientId: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID || '',
    onLogoutSuccess: () => console.log('Log out successfully'),
  });

  const onChange = e => {
    const { value } = e.target;

    if (value === 'login') {
      navigate(PATH.LOGIN);
    } else {
      navigate(PATH.REGISTER);
    }

    form.resetFields();
    setKey(value);
  };

  const onFinish = useCallback(
    (values: any, tokenResponse) => {
      if (pathname === PATH.REGISTER) {
        delete values.confirmPassword;
        dispatch(
          actions.register({
            email: values?.email,
            password: values?.password,
            username: values?.username,
          }),
        );
      } else {
        // Login with Google
        if (tokenResponse) {
          const { email, name } = tokenResponse?.profileObj;
          const values = {
            idToken: tokenResponse?.tokenId,
            username: name,
            email: email,
          };

          dispatch(
            actions.loginOAuth({
              values: values,
              searchParam: searchParams.get('username') || '',
            }),
          );
        } else {
          dispatch(
            actions.login({
              values: {
                email: values?.email,
                password: values?.password,
              },
              searchParam: searchParams.get('username') || '',
            }),
          );
        }

        setTokenResponse(null);
        setSearchParams('');
      }

      form.resetFields();
      dispatch(actionsToast.closeToast({}));
    },
    [actions, dispatch, pathname],
  );

  const onLoginOAuth = tokenResponse => {
    setTokenResponse(tokenResponse);
    onFinish(form.getFieldsValue(true), tokenResponse);
  };

  useEffect(() => {
    form.setFieldsValue({ username: '', password: '' });
  }, [form]);

  useEffect(() => {
    if (pathname === PATH.LOGIN) {
      setKey('login');
    } else {
      setKey('register');
    }
  }, [pathname]);

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.scrollTop =
        ref.current.clientHeight - ref.current.scrollHeight;
    }
  }, [pathname]);

  useEffect(() => {
    if (Cookies.get(STORAGE_KEY.ACCESS_TOKEN)) {
      navigate(PATH.HOME);
    }
  }, []);

  return (
    <Wrapper ref={ref}>
      <MetaHelmet title="FU-CHA" />

      <Box className="form-content">
        <LogoAuth>
          <h1 className="logo-text logo">セクシーな生配信「ふーちゃ」</h1>
          <div className="logo-image">
            <img src={Logo} alt="top-donation" />
          </div>
          <small className="logo-notice logo-text">
            -18歳未満の方の当サイトのご利用はお断りいたします-
          </small>
        </LogoAuth>
        <TabBox>
          <Radio.Group onChange={onChange} value={key}>
            <Radio.Button value="login">ログイン</Radio.Button>
            <Radio.Button value="register">新規登録</Radio.Button>
          </Radio.Group>
        </TabBox>

        {key === 'login' ? (
          <LoginForm
            form={form}
            onFinish={onFinish}
            onLoginOAuth={onLoginOAuth}
          />
        ) : (
          <RegisterForm form={form} onFinish={onFinish} isLoading={isLoading} />
        )}
        <PartnerSite />
      </Box>
      <CarouseBox>
        <Carousel
          autoplay
          effect="fade"
          speed={2000}
          infinite
          className="carousel-custom"
        >
          <Image
            src={isTablet ? BannerIpad : LoginBanner}
            alt="login_banner"
            preview={false}
            className="banner_img"
          />
        </Carousel>
      </CarouseBox>
    </Wrapper>
  );
});

// css
const Wrapper = styled.div`
  overflow: hidden;
  width: 100vw;
  height: 100dvh;
  display: grid;
  grid-template-columns: 29% calc(100% - 29%);
  justify-content: space-between;
  overflow: auto;
  overflow-x: hidden;
  background-image: url('${loginBg}');
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;

  @media only screen and (max-width: 1440px) {
    grid-template-columns: 420px calc(100% - 420px);
  }
  @media only screen and (max-width: 703px) {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
  }
  .ant-image {
    width: 100%;
    height: 100%;
    outline: none;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  .form-content {
    max-width: 328px;
    width: 100%;
    margin: auto;
    justify-content: flex-start;
    margin-top: 50px;
    height: max-content;
    @media only screen and (max-width: 1440px) {
      margin-top: 50px;
    }
    @media only screen and (max-width: 703px) {
      margin: auto;

      max-width: 500px;
      padding: 31px;
    }
  }

  .form-login {
    width: 100%;
  }

  .forgot-pass {
    a {
      position: absolute;
      right: 0;
      bottom: -25px;
      color: #928c8c;
      font-size: 12px;
      font-style: italic;
      text-decoration: underline;
      &:hover {
        color: #eb7d17;
      }
    }
  }

  .ant-btn-primary {
    margin-top: 20px;
    width: 100%;
    height: 52px;
    font-size: 18px;
  }
  .ant-form-item-explain-error {
    width: fit-content;
  }

  .banner_img {
    border-top-left-radius: 60px;
    border-bottom-left-radius: 60px;

    @media only screen and (max-width: 703px) {
      border-radius: 0;
    }
  }
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const TabBox = styled.div`
  margin-top: 34px;
  margin-bottom: 20px;
  width: 100%;

  .ant-radio-group {
    background-color: #494443;
    border-radius: 10px;
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .ant-radio-button-wrapper {
    height: 48px;
    padding: 4px;
    background-color: #494443;
    span {
      display: flex;
      align-items: center;
      height: 100%;
      color: #928c8c;
      font-size: 18px;
      font-weight: 700;
      justify-content: center;
      border-radius: 8px;
    }
  }
  .ant-radio-button-wrapper-checked {
    span {
      color: #fff;
    }
    span:nth-child(2) {
      background-color: #231f1f;
    }
  }
  .ant-radio-button-wrapper:not(:first-child)::before {
    display: none;
  }
  .ant-radio-button-wrapper:first-child {
    border-right: 0px;
    border-start-start-radius: 8px;
    border-end-start-radius: 8px;
  }
  .ant-radio-button-wrapper:last-child {
    border-start-end-radius: 8px;
    border-end-end-radius: 8px;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    border-color: #494443 !important;
  }
  .ant-radio-button-wrapper {
    border: 1px solid #494443 !important;
  }
`;

const CarouseBox = styled.div`
  @media only screen and (max-width: 1024px) {
    .ant-image {
      img {
        object-fit: cover;
      }
    }
  }
  @media only screen and (max-width: 703px) {
    height: 280px;
    width: 100%;
  }

  div {
    height: 100% !important;
  }
  .ant-carousel {
    height: 100% !important;

    @media only screen and (max-width: 703px) {
      height: 100% !important;
    }
  }
  .ant-carousel:focus-visible {
    outline: none;
  }
  .ant-carousel,
  .ant-carousel .slick-slider,
  .slick-list,
  .slick-track {
    height: 100dvh;
  }
  .slick-dots {
    align-items: center;
    li {
      width: 8px;
      height: 8px;
      border-radius: 100%;
      margin-inline: 10px;
      button {
        width: 100%;
        height: 100%;
        border-radius: 100%;
      }
    }
    li.slick-active {
      width: 18px;
      height: 18px;
    }
  }
`;

const LogoAuth = styled.div`
  .logo-text {
    text-align: center;
    color: #fff;
  }

  .logo {
    font-size: 17px;
  }

  .logo-notice {
    font-size: 11px;
  }

  .logo-image {
    text-align: center;
    margin-bottom: 6px;
  }
`;
